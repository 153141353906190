<template>
  <div>
    <ProjectDetailsExportScheduleModal
      :custom-views="customViews"
      :value.sync="dialogForLimitByTag"
      @export="exportProject" />
    <v-card class="elevation-0">
      <v-list :key="listKey">
        <!-- export to pdf -->
        <v-list-item
          v-if="isVisiblePrint"
          @click="externalPrint">
          <v-list-item-title>
            Quick PDF Export
          </v-list-item-title>
        </v-list-item>

        <!-- groups -->
        <v-list-group
          v-for="(item, i) in filteredExportItems"
          :key="i"
          no-action>
          <template #activator>
            <v-list-item-title
              class="pointer"
              @click="selectItem(item.template, 'templateName')">
              {{ item.name.title }}
            </v-list-item-title>
          </template>
          <ExportToByCustomViewBlock
            v-if="isShowLimitProducts && item.name.value !== CURRENT_VIEW_VALUE"
            :value="customLimitsByView[item.name.value]"
            :show-tooltip="showCustomViewsTooltip"
            @change="
              changeCustomLimit({
                value: $event,
                type: item.name.value
              })
            " />
          <v-list-item
            v-for="(typeItem, typeIndex) in item.types"
            :key="typeIndex"
            :disabled="typeItem.disabled"
            :class="{ 'item-disabled': typeItem.disabled }">
            <template>
              <v-tooltip
                :disabled="!typeItem.showTooltip"
                left>
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="d-flex"
                    v-bind="attrs"
                    v-on="on">
                    <v-icon
                      v-if="typeItem.icon"
                      :style="typeItem.margin && 'margin-right: 2px;'"
                      :color="typeItem.disabled ? 'rgb(187,187,187)' : ''">
                      {{ typeItem.icon }}
                    </v-icon>
                    <img
                      v-else
                      :src="typeItem.customIcon"
                      :alt="typeItem.customIcon"
                      class="type-item-custom-icon">
                    <v-list-item-title
                      class="pointer"
                      @click="
                        exportProjectHandle({
                          name: typeItem.customPdfType || item.name.value,
                          templateName: item.template,
                          fileFormat: typeItem.name,
                          exportType: typeItem.value,
                        })
                      ">
                      {{ typeItem.label ? typeItem.label : typeItem.name }}
                      <span
                        v-if="typeItem.desc"
                        class="text-body-2">
                        {{ ` - ${typeItem.desc}` }}
                      </span>
                    </v-list-item-title>
                  </div>
                </template>
                <span>{{ typeItem.tooltipText }}</span>
              </v-tooltip>
            </template>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-card>
  </div>
</template>
<script>
import {
  mapState, mapGetters, mapMutations,
} from 'vuex';

import { NOT_PERMITTED } from '@/constants/userPermissions';
import {
  XLSX,
  PDF,
  DOCX,
  CSV,
  CUT_SHEET,
  HLB_SCHEDULE,
  ELECTRO_VALUE,
  HH_ANGUS_EXPORT,
  TEMPLATE_HH_ANGUS_EXPORT,
  TEMPLATE,
  TEMPLATE_CUT_SHEET_HLB,
  TEMPLATE_MBI_LUMINAIRES_NEW,
  MBI_LUMINAIRES_NEW,
  MBI_LUMINAIRES_OLD,
  SPECIFICATION_SHEET,
  ZIP,
  HH_ANGUS_EXPORT_VALUE,
  TEMPLATE_ZIP,
  SPECIFICATION_SHEETS,
  CURRENT_VIEW_VALUE,
  ELECTRO_LIGHT_EXPORT,
  TEMPLATE_ELECTRO_LIGHT_LUMINAIRE_SCHEDULE,
  MCW_VALUE,
  MCW_EXPORT,
  MCW_TEMPLATE,
  JOURNEY_WORLD,
} from '@/constants/exportScheduleItems';
import { SORTABLE_ACTIONS_WIDTH } from '@/constants/scheduleViews.js';

import ExportProject from '@/mixins/ExportProject';
import ToggleViewCondition from '@/mixins/ToggleViewCondition';

export default {
  name: 'ProjectDetailsExportTo',
  components: {
    ProjectDetailsExportScheduleModal: () => import('./ProjectDetailsExportScheduleModal'),
    ExportToByCustomViewBlock: () => import('./ProjectDetailsExportToByCustomViewBlock'),
  },
  mixins: [ExportProject, ToggleViewCondition],
  props: {
    isVisiblePrint: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      usersCanUseHangus: [],
      usersCanUseElctrolight: [],
      usersCanUseMCW: [],
      usersCanUseCutSheet: [
        'lgtingtests+usertest21@gmail.com',
        'lgtingtests+CI@gmail.com',
        'aks1k15@gmail.com',
      ],
      usersCanUseJourney: [
        'daryl.ho@expogain.com',
      ],
      showExportMenu: false,
      exportingSubType: null,
      CURRENT_VIEW_VALUE,
      isVisibleTable: false,
      printFormat: 'a0',
      NOT_PERMITTED,
    };
  },
  computed: {
    ...mapState(['role', 'userInfo']),
    ...mapGetters({
      customViews: 'ScheduleViews/customViewsForExportToSchedule',
      canUseExportTo: 'UserRoles/canUseExportTo',
      canUseExportToForCollaboratorRole: 'UserRoles/canUseExportToForCollaboratorRole',
    }),
    ...mapGetters('ScheduleRows', ['sortedViewInfo']),
    ...mapGetters('ProjectDetails', ['getDetailProjectTitle']),
    ...mapGetters('ScheduleViews', ['tableHeaders', 'selectedView']),
    /**
     * Compute whether the user can use the export feature based on their role.
     *
     * @returns {boolean} `true` if the user can use the export feature, otherwise `false`.
     */
    canUseExportToComputed() {
      const { role, canUseExportTo } = this;
      return canUseExportTo(role);
    },
    /**
     * Compute whether the user with a collaborator role can use the export feature based on their role.
     *
     * @returns {boolean} `true` if the user with a collaborator role can use the export feature, otherwise `false`.
     */
    canUseExportToForCollaboratorRoleComputed() {
      const { role, canUseExportToForCollaboratorRole } = this;
      return canUseExportToForCollaboratorRole(role);
    },
    email() {
      const { email = '' } = this.userInfo || {
      };
      return email;
    },
    showCustomViewsTooltip() {
      const { customViews: views = [] } = this;
      return !views.length;
    },
    isMbiUser() {
      const { email } = this;
      if (!email) return false;
      return email.includes('@mbii.com')
        || email.includes('@gosourcery.com')
        || this.usersCanUseCutSheet.includes(email);
    },
    isHlblightingUser() {
      const { email } = this;
      if (!email) return false;
      return email.includes('@hlblighting.com')
        || email.includes('@gosourcery.com')
        || this.usersCanUseCutSheet.includes(email);
    },
    isAvailableHangus() {
      const { email } = this;
      if (!email) return false;
      return email.includes('@hhangus.com')
        || email.includes('@gosourcery.com')
        || this.usersCanUseHangus.includes(email);
    },
    isElectroLightAvalilable() {
      const { email } = this;
      if (!email) return false;
      return (
        email.includes('@electrolight.com') ||
        email.includes('@gosourcery.com') ||
        this.usersCanUseElctrolight.includes(email)
      );
    },
    isAvailableMCW() {
      const { email } = this;
      if (!email) return false;
      return email.includes('@mcw.com')
        || email.includes('@gosourcery.com')
        || this.usersCanUseMCW.includes(email);
    },
    isAvailableJourney() {
      const { email } = this;
      if (!email) return false;
      return email.includes('@journey.world') || email.includes('@gosourcery') || email.includes('@icrave') || this.usersCanUseJourney.includes(email);
    },
    /**
     * Get an array of items that can be used.
     *
     * @returns {Array} An array of items that can be used.
     */
    filteredExportItems() {
      /**
       * Filter the items array to include only items that can be used and have appropriate roles.
       *
       * @param {Object} item - An item object.
       * @param {boolean} [item.canUse=true] - Indicates whether the item can be used.
       *                                      Defaults to `true` if not provided.
       * @param {boolean} [item.canUseAppropriateRole=false] - Indicates whether the item can be used with appropriate roles. Defaults to `false` if not provided.
       * @returns {boolean} `true` if the item can be used and has appropriate roles, otherwise `false`.
       */
      const filterCallback = ({ canUse = true, canUseAppropriateRole = false }) =>
        canUse && canUseAppropriateRole;
      return this.exportItems.filter(filterCallback);
    },
    exportItems() {
      const {
        canUseExportToForCollaboratorRoleComputed: canUseToCollaborator,
        canUseExportToComputed: canUseExport,
      } = this;
      return [
        {
          canUse: this.isMbiUser,
          canUseAppropriateRole: canUseToCollaborator,
          name: {
            value: CUT_SHEET,
            title: MBI_LUMINAIRES_OLD,
          },
          template: TEMPLATE,
          types: [
            {
              name: DOCX,
              icon: 'mdi-file-document',
            },
            {
              name: PDF,
              icon: 'mdi-file-pdf-box',
            },
          ],
        },
        {
          canUse: this.isMbiUser,
          canUseAppropriateRole: canUseToCollaborator,
          name: {
            value: CUT_SHEET,
            title: MBI_LUMINAIRES_NEW,
          },
          template: TEMPLATE_MBI_LUMINAIRES_NEW,
          types: [
            {
              name: DOCX,
              icon: 'mdi-file-document',
            },
            {
              name: PDF,
              icon: 'mdi-file-pdf-box',
            },
          ],
        },
        {
          canUse: this.isHlblightingUser,
          canUseAppropriateRole: canUseToCollaborator,
          name: {
            value: HLB_SCHEDULE,
            title: HLB_SCHEDULE,
          },
          template: TEMPLATE_CUT_SHEET_HLB,
          types: [
            {
              name: XLSX,
              icon: 'mdi-file-excel',
            },
            {
              name: PDF,
              icon: 'mdi-file-pdf-box',
            },
          ],
        },
        {
          canUse: this.isElectroLightAvalilable,
          canUseAppropriateRole: canUseToCollaborator,
          name: {
            value: ELECTRO_VALUE,
            title: ELECTRO_LIGHT_EXPORT,
          },

          template: TEMPLATE_ELECTRO_LIGHT_LUMINAIRE_SCHEDULE,
          types: [
            {
              name: XLSX,
              icon: 'mdi-file-excel',
            },
          ],
        },
        {
          canUse: this.isAvailableHangus,
          canUseAppropriateRole: canUseToCollaborator,
          name: {
            title: HH_ANGUS_EXPORT,
            value: HH_ANGUS_EXPORT_VALUE,
          },
          template: TEMPLATE_HH_ANGUS_EXPORT,
          types: [
            {
              name: XLSX,
              icon: 'mdi-file-excel',
            },
          ],
        },
        {
          canUse: this.isAvailableMCW,
          canUseAppropriateRole: canUseToCollaborator,
          name: {
            title: MCW_EXPORT,
            value: MCW_VALUE,
          },
          template: MCW_TEMPLATE,
          types: [
            {
              name: XLSX,
              icon: 'mdi-file-excel',
            },
          ],
        },

        {
          canUse: this.isAvailableJourney,
          canUseAppropriateRole: canUseToCollaborator,
          name: {
            title: JOURNEY_WORLD,
            value: JOURNEY_WORLD,
          },
          template: '',
          types: [
            {
              name: XLSX,
              icon: 'mdi-file-excel',
            },
            {
              name: PDF,
              icon: 'mdi-file-pdf-box',
            },
          ],
        },
        this.currentViewExport,
        {
          name: {
            value: SPECIFICATION_SHEET,
            title: SPECIFICATION_SHEETS,
          },
          canUseAppropriateRole: canUseExport,
          template: TEMPLATE_ZIP,
          types: [
            {
              name: ZIP,
              icon: 'mdi-folder-zip',
              margin: true,
            },
          ],
        },
        {
          name: {
            value: 'AllFieldsCSVExport',
            title: 'All Fields CSV Export',
          },
          canUseAppropriateRole: canUseExport,
          template: '',
          types: [
            {
              name: CSV,
              icon: 'mdi-file-excel',
            },
          ],
        },
      ];
    },
    getWidthOfTable() {
      let tableWidth = 0;
      for (let i = 0; i < this.tableHeaders.length; i++) {
        const column = this.tableHeaders[i];
        const {
          columnSize = {
            size: 60,
            isCustomSortable: false,
          },
        } = column ?? {
        };
        const {
          size,
          isCustomSortable,
        } = columnSize;
        if (isCustomSortable) {
          tableWidth += size + SORTABLE_ACTIONS_WIDTH;
        } else {
          tableWidth += size;
        }
      }
      let listWidth;
      let result;
      if (this.printFormat === 'tabloid') {
        // Tabloid = 11×17
        listWidth = 17 * this.inc;
        result = 100 * (tableWidth / listWidth) * 1.15;
      }
      if (this.printFormat === 'a0') {
        // A0 = 33.1×46.8
        listWidth = 33.1 * this.inc;
        result = 100 * (tableWidth / listWidth);
      }
      if (this.printFormat === 'a1') {
        // A1 = 23.4×33.1
        listWidth = 23.4 * this.inc;
        result = 100 * (tableWidth / listWidth);
      }
      if (this.printFormat === 'a2') {
        // A2 = 16.5×23.4
        listWidth = 16.5 * this.inc;
        result = 100 * (tableWidth / listWidth);
      }
      return Math.ceil(result);
    },
    htmlToPdfOptions() {
      return {
        margin: 0,
        filename: `${this.getCurrentView} - ${this.getDetailProjectTitle}.pdf`,
        enableLinks: true,
        html2canvas: {
          dpi: 600,
          scale: 2,
          removeContainer: true,
        },
        jsPDF: {
          unit: 'in',
          format: this.printFormat,
          orientation: 'landscape',
          quality: 0.85,
          compress: true,
        },
      };
    },
    getScale() {
      const width = this.getWidthOfTable;
      const result = 100 / width;
      if (result > 1) {
        return 1;
      }
      return result;
    },
    isListingView() {
      return this.toggleViewCondition(this.selectedViewId);
    },
    getCurrentView() {
      const { name = '' } = this.selectedView ?? {
      };
      return name;
    },
  },
  watch: {
    showExportMenu() {
      if (!this.showExportMenu) {
        this.resetSelections();
      }
    },
  },
  methods: {
    changeCustomLimit({ value, type }) {
      this.customLimitsByView = {
        ...this.customLimitsByView,
        [type]: value,
      };
    },
    ...mapMutations({
      openSnackBar: 'openSnackBar',
    }),
    selectItem(val, name) {
      this.$set(this, name, val);
    },
    hasDownloaded() {
      this.isVisibleTable = false;
    },
    onProgress(event) {
      const isOver = event === 100;

      if (isOver) {
        this.spinner(false);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .v-list-item__icon {
  align-self: center;
}

::v-deep.v-list-group > .v-list-group__items > .v-list-item__subtitle {
  padding-inline-start: 16px;
}

.type-item-custom-icon {
  width: 24px;
  padding: 1px;
  margin-right: 1px;
  filter: grayscale(1);
  opacity: .9;
}
</style>